// ::ng-deep .mat-focused .mat-form-field-label {
//   /*change color of label*/
//   color: green !important;
//  }

//  ::ng-deep.mat-form-field-underline {
//   /*change color of underline*/
//   background-color: green !important;
// }

// ::ng-deep.mat-form-field-ripple {
//  /*change color of underline when focused*/
//  background-color: green !important;;
// }
* {
  font-family: "DM Sans";
  /* Add !important to overwrite all elements */
}
// Importants CSS
@import "~@ionic/angular/css/core.css";
@import "./theme/variables.scss";
* {
  scrollbar-width: thin;
  scrollbar-color: #a3a3a3 #fafafa;
}
*::-webkit-scrollbar {
  width: 13px;
}
*::-webkit-scrollbar-track {
  background: #fafafa;
  border-radius: 15px;
}
*::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 15px;
  border: 3px solid #a3a3a3;
}
button:disabled {
  opacity: 0.3 !important;
  // background: #dddddd !important;
  // background-color: #dddddd !important;
  // color: #bdbdbd !important;
}
input,
select,
textarea {
  color: #000;
}
.generalBackgroundImg {
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.bgfakewhite {
  background-color: #fbfbfb !important;
}
.darkDivCover {
  height: 100vh !important;
  width: 100vw !important;
  background-color: #0000009c;
  position: absolute;
  top: 0px;
  left: 0px;
}
.wh100pr {
  width: 100%;
  height: 100%;
}
.w100pr {
  width: 100% !important;
}
.w50pr {
  width: 50%;
}
.fakeIcon {
  width: 24px;
  height: 24px;
  margin-right: 9px;
}
.sideIcon {
  margin-right: 10px;
}
.drawAndDrop {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.drawAndDrop:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
ion-slides {
  height: 100%;
}
.pb12rem {
  padding-bottom: 1.2rem;
}
.pb26px {
  padding-bottom: 26px;
}
.textCenter {
  text-align: -webkit-center;
}
.mat-button.mat-success,
.mat-stroked-button.mat-success {
  color: #155724;
}
.mat-button.mat-success:hover,
.mat-stroked-button.mat-success:hover {
  background-color: #f0fff3;
}
.mat-raised-button.mat-success,
.mat-flat-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  color: #f0fff3;
  background-color: #155724;
}
.mat-icon-button.mat-success {
  color: #155724;
}
div:focus,
.mat-tab-label-active {
  opacity: 1 !important;
}
div:focus > .mat-tab-label-content,
.mat-tab-label-active > .mat-tab-label-content {
  color: #4a4a4a !important;
}
.mat-tab-label-content {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 183.2%;
  color: #adadad;
}
.btnBlue2 {
  max-width: 100%;
  min-height: 50px;
  width: 208px;
  background: linear-gradient(90deg, #46a3ff 0%, #1ed2d2 155.05%) !important;
  color: white !important;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 183.2% !important;
  border-radius: 11px !important;
}
.divSpinner {
  text-align: -webkit-center;
}
.taright {
  text-align: right;
}
.h100pr {
  height: 100%;
}
.container {
  max-height: 100%;
}
form {
  max-height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.whiteColor {
  color: white;
}
.titleIcon {
  width: auto;
  height: 16vh;
}
.leftText {
  text-align: initial;
}
.btnBlue {
  background-color: $blue !important;
  color: white !important;
}
.btnRed {
  background-color: $invitadoRedColor;
  color: white;
}
.btnBlack {
  background-color: $black !important;
  color: white !important;
}
.bigTitle {
  line-height: 1em;
  font-weight: bold;
  font-size: x-large;
}
.miniText {
  color: white;
  font-size: smaller;
}
.centerMiddle {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: -webkit-center;
  justify-content: center;
}
.mb2vh {
  margin-bottom: 2vh;
}
.mb22px {
  margin-bottom: 22px;
}
.mb20px {
  margin-bottom: 20px;
}
.mb32px {
  margin-bottom: 32px;
}
.mt10px {
  margin-top: 10px;
}
.textWhite {
  color: white;
}
.textGray {
  color: #c8c8c8;
}
.textBlack {
  color: black;
}
.textDarkGray {
  color: #3f3f3f;
}
.textGreen {
  color: #59cc31;
}
.customInputWhite.mat-form-field {
  background-color: white;
  padding: 6px 15px;
  border-radius: 10px;
}
.mb15px {
  margin-bottom: 15px;
}
input {
  background-color: white;
  color: black;
  text-shadow: none !important;
}
input:valid {
  background-color: white;
  color: black !important;
  text-shadow: none !important;
}
input:focus {
  background-color: white;
  color: black !important;
  text-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
button {
  width: -webkit-fill-available;
}
.a-black-text-decoration-none {
  color: black;
  text-decoration: none;
}
.zi10 {
  z-index: 10;
}
.SoloLogo {
  width: 50px;
  margin-right: 12px;
}
.pb24px {
  padding-bottom: 24px;
}
.mb24px {
  margin-bottom: 24px;
}
.fullContainer,
#fullContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.sideNabContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.BtnToogleSideBar {
  z-index: 100;
  background-color: #45454523;
  width: 30px;
  height: 100%;
  border: 3px solid #45454523;
  border-radius: 0px 8px 8px 0px;
  border-left: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
}
// .BtnToogleSideBar:hover {
//   background-color: #7b7b7b;
//   border: 0px;
// }
.containerMarginsDefault {
  margin-left: $sideBarWidth;
  margin-right: $sideBarWidth;
}
.dInline {
  display: inline;
}
.tl0posabs {
  top: 0px;
  left: 0px;
  position: absolute;
}
mat-vertical-stepper {
  background-color: transparent !important;
}
.mat-step-header {
  padding: 0.5em;
}
.mat-vertical-content-container {
  margin-left: 0px !important;
  padding-right: 0px;
  padding-left: 1em;
  padding-bottom: 0px;
}
.whiteBG {
  background-color: white !important;
}
.blackBG {
  background-color: black;
}
.componentsParent > *:nth-child(2) {
  width: 100%;
  height: 100%;
  position: relative;
}
.mb05em {
  margin-bottom: 0.5em;
}
.mat-form-field,
.wwfa {
  width: -webkit-fill-available;
}
@media screen and (max-width: 321px) {
  .titleIcon {
    width: 120.57px;
    height: 93.18px;
  }
  .bigTitle {
    font-size: large;
  }
  .mb2vh {
    margin-bottom: 1vh;
  }
  button {
    font-size: 0.8rem !important;
  }
}
@media screen and (max-width: 361px) {
  .searchShadow {
    width: 100% !important;
  }
  .searchFrm {
    width: 68% !important;
  }
  .bigTitle {
    font-size: x-large;
  }
}
.loadingPopUp {
  background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url("/assets/FotoBackground.jpg") no-repeat center center fixed;
}
.loadingPopUpPanel > mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-top: 0px;
}
.posRelative {
  position: relative;
}
.hFitContent {
  height: fit-content;
}
.btnX {
  position: absolute !important;
  right: 0px;
  top: 0px;
}
.bgDegradadoAzul {
  background: linear-gradien(left, #1ed2d2, #46a3ff);
  background: -webkit-linear-gradient(left, #1ed2d2, #46a3ff);
  background: -moz-linear-gradien(left, #1ed2d2, #46a3ff);
  background: -o-linear-gradien(left, #1ed2d2, #46a3ff);
  background: -ms-linear-gradien(left, #1ed2d2, #46a3ff);
}
.bodyhome {
  position: absolute;
  top: 0px;
  width: 100%;
  align-items: center;
}
// @media screen
//   and (min-width: 768px) {
//     .BigScreenMR1em {
//       // margin-right: 1em;
//     }
//   }
.sideBar {
  width: 261px;
}
.bgred {
  background-color: red;
}
.testingbigdiv {
  width: 100%;
  height: 300vh;
  background-color: gold;
}
.tittlePrincHouse {
  font-weight: 500;
  margin-bottom: 0px;
}
.subtitlePrincHouse {
  margin-top: 0px;
  padding: 0px 20px;
}
.pricePrincHouse {
  font-weight: 500;
}
.principalCard {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
  margin-bottom: 20px;
}
.principalCardHeader {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.principalCardContent {
  padding: 0px;
  border-radius: 0px;
}
.principalCardContent > * {
  border-radius: 0px;
}
.pac-logo:after {
  visibility: collapse;
  height: 0px;
}
.pac-container {
  border-radius: 0px 0px 10px 10px;
}
.swiper-pagination {
  bottom: 0px !important;
}
.contfather {
  position: relative;
  overflow: hidden;
}
.forceContentCarr {
  object-fit: fill !important;
}
.lbuttoncarrcont {
  left: 2%;
}
.rbuttoncarrcont {
  right: 2%;
}
.lbuttoncarrcont,
.rbuttoncarrcont {
  top: 0px;
  height: 100%;
  display: flex;
  z-index: 10;
  position: absolute;
  align-items: center;
}
.carrbtn {
  background-color: $transparentGrayColor !important;
}
.indexNumber {
  top: 2%;
  right: 2%;
  z-index: 3;
  position: absolute;
  background-color: #ffffff50;
  color: #ffffff;
  padding: 3px;
  border-radius: 5px;
}
.carrImg {
  object-fit: contain; // Alternative: cover
}
.hideIfSmall {
  visibility: visible;
}
@media screen and (max-width: 361px) {
  .hideIfSmall {
    visibility: collapse !important;
  }
}
.cdk-global-overlay-wrapper {
  background: #000000d5; // Fix modal Carousel 3
}
.noselect,
mat-icon {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.dflexCenter {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-center-left {
  display: flex;
  align-items: center;
  justify-content: left;
}
.dflexCenterRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.d-none {
  display: none;
}
.customInputSlider
  > .mat-mdc-text-field-wrapper
  > .mat-mdc-form-field-flex
  > .mat-form-field-infix {
  padding-bottom: 0px;
  padding-top: 0px;
}
.customInputSlider
  > .mat-mdc-text-field-wrapper
  > .mat-mdc-form-field-flex
  > .mat-form-field-infix
  > mat-slider {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 44px;
}
.boldtext {
  font-weight: bold !important;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
mat-spinner {
  opacity: 0.1;
}
.frowfwrap {
  flex-direction: row;
  flex-wrap: wrap;
}
.mt15px {
  margin-top: 15px;
}
.pt15px {
  padding-top: 15px;
}
.boldText {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 33px;
}
.primaryStrokedBtn {
  font-family: DM Sans;
  border-radius: 5px !important;
  font-style: normal;
  min-height: 50px;
  font-weight: bold;
  font-size: 16px;
  line-height: 183.2% !important;
  color: $primary;
}
.degradadoStrokedBtn {
  font-family: DM Sans !important;
  border-radius: 5px !important;
  font-style: normal;
  min-height: 50px;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 183.2%;
  color: #ffffff !important;
  background: linear-gradient(90deg, #46a3ff 0%, #1ed2d2 155.05%) !important;
  display: block !important;
  white-space: break-spaces !important;
}
.mr24px {
  margin-right: 24px;
}
.customInputForButton {
  width: 100%;
}
.customInputForButton
  > .mat-mdc-text-field-wrapper
  > .mat-mdc-form-field-flex
  > .mat-mdc-form-field-icon-suffix {
  display: flex;
  align-self: stretch;
}
.customInputNoMP > .mat-mdc-text-field-wrapper {
  padding: 0 0.75em 0 0.75em !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
mat-select {
  height: 15px;
}
.customBtnInsideInput {
  height: 100% !important;
  border-radius: 0px 5px 5px 0px !important;
}
.customInputNoMP {
  height: 56px;
}
.customInputNoMP > .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
@media screen and (max-width: 491px) {
  .hide-sm {
    display: none !important;
  }
  .show-sm {
    display: block;
  }
  .h80px-sm {
    height: 80% !important;
  }
}
.customInputForIconInfix
  > .mat-mdc-text-field-wrapper
  > .mat-mdc-form-field-flex
  > .mat-mdc-form-field-icon-prefix {
  padding-left: 5px;
}
@media screen and (min-width: 491px) {
  .show-sm {
    display: none !important;
  }
  .customInputForButton
    > .mat-mdc-text-field-wrapper
    > .mat-mdc-form-field-flex {
    padding-right: 0px;
  }
}
.mffmb {
  margin-bottom: 1.59375em !important;
}
mat-form-field {
  background-color: white;
}
.mat-mdc-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mat-mdc-menu-panel {
  overflow: visible !important;
  max-width: 100% !important;
  margin-top: 6px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}
.fakeA,
.ajs-message {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}
.ajs-warning {
  background-color: $warningColor !important;
  color: $textWarningColor !important;
}
.ajs-info {
  background-color: $primary !important;
  color: white !important;
}
.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #46a3ff !important;
}

// overwrite the ripple overlay on hover and click
.mat-checkbox:not(.mat-checkbox-disabled)
  .mat-checkbox-ripple
  .mat-ripple-element {
  background-color: #46a3ff !important;
}
.mat-drawer-content {
  z-index: 9;
  display: inline;
}
.OYAuto {
  overflow-y: auto !important;
}
.overflowInherit {
  overflow: inherit !important;
}
.hFillContent {
  height: intrinsic !important;
  height: -moz-available !important; /* For Mozzila */
  height: -webkit-fill-available !important; /* For Chrome */
  height: stretch !important; /* Unprefixed */
}
.wFillContent {
  width: intrinsic !important;
  width: -moz-available !important; /* For Mozzila */
  width: -webkit-fill-available !important; /* For Chrome */
  width: stretch !important; /* Unprefixed */
}
.fakeUrl {
  cursor: pointer;
}
.childContainer {
  max-height: calc(100vh - #{$NavBarHeight});
  width: 100%;
  height: 100%;
  position: relative;
}
.cardWhiteProspectos {
  margin: 12px;
  background-color: white;
  border-radius: 20px;
  padding-inline: 20px;
  padding-block: 21px;
  box-shadow: 0px 1px 30px rgba(9, 87, 203, 0.08);
}
.cardIconCont {
  display: flex;
  align-items: center;
  width: 100%;
}
.cardIconContOld {
  display: flex;
  align-items: center;
}
.cardIconText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 183.2%;
  color: #4a4a4a;
  height: fit-content;
  width: calc(100% - 24px);
  text-align: center;
}
.cardIconTextOld {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 183.2%;
  color: #4a4a4a;
  height: fit-content;
  margin-left: 14px;
  margin-right: 36px;
}
.cardIconsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.likeIcon {
  color: #eb5757 !important;
}
.likeIconDisabled {
  color: #adadad !important;
}
// .cardIcon {
//   margin-right: 3px;
// }
.seeMore {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: #46a3ff;
  display: flex;
  align-items: center;
}
.seeMore > mat-icon {
  width: 15px;
  height: 15px;
  display: flex;
  margin-left: 5px;
}
.gm-style-iw-d {
  max-height: 100% !important;
}
app-navbar {
  height: 150px;
}
.custom-oval-mat-form-field .mdc-notched-outline__leading {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px !important;
}
.custom-oval-mat-form-field .mdc-notched-outline__trailing {
  border-radius: 0 28px 28px 0 !important;
}
.custom-semi-oval-mat-form-field .mdc-notched-outline__leading {
  border-radius: 11px 0 0 11px !important;
  min-width: 11px !important;
}
.custom-semi-oval-mat-form-field .mdc-notched-outline__trailing {
  border-radius: 0 11px 11px 0 !important;
}
.custom-blue-mat-form-field .mdc-notched-outline__leading {
  border-color: transparent !important;
  background: rgba(236, 246, 255, 0.5);
}
.custom-blue-mat-form-field .mdc-notched-outline__notch {
  border-color: transparent !important;
  background: rgba(236, 246, 255, 0.5);
}
.custom-blue-mat-form-field .mdc-notched-outline__trailing {
  border-color: transparent !important;
  background: rgba(236, 246, 255, 0.5);
}
.custom-blue-mat-form-field input {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 183.2% !important;
  color: #000000 !important;
}
.mat-form-field-invalid.custom-blue-mat-form-field
  .mdc-notched-outline__leading {
  border-color: #e30027 !important;
}
.mat-form-field-invalid.custom-blue-mat-form-field .mdc-notched-outline__notch {
  border-color: #e30027 !important;
}
.mat-form-field-invalid.custom-blue-mat-form-field
  .mdc-notched-outline__trailing {
  border-color: #e30027 !important;
}
.loginContainer {
  width: 100%;
  height: 100%;
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
  padding: 24px;
  overflow-y: auto;
}
.loginLogo {
  display: flex;
}
.loginFormContainer {
  padding: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 328px;
  max-width: 100vw;
}
.loginWelcomeText {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
}
.loginWelcomeText2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
}
.eye-icon {
  color: #46a3ff;
}
.loginCard {
  box-shadow: 0px 1px 30px rgba(9, 87, 203, 0.08);
  border-radius: 20px;
  background: white;
}
.fake-hr {
  width: 100%;
  height: 1px;
  background-color: #dadada;
}
.contact-us-register-container {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 183.2%;
  display: flex;
  align-items: center;
  color: #80858a;
}
.forgot-password {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 183.2%;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
  color: #46a3ff;
}
.forgot-password-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.login-alternative-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
}
.alternative-logins-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.social-media-login-button {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: none;
  background-color: white;
}
.social-media-login {
  width: 32px;
  height: 32px;
}