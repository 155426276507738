$fbColor: #2A5297;
$ggColor: #EC352E;
$invitadoRedColor: #E30027;
$warningColor: #fff42e;
$textWarningColor: #555555;
$btnBlackColor: #262626;
$blue: #2F76EC;
$primary: #0080FF;
$black: #262626;
$transparentGrayColor: #ffffff32;
$locationColor: #ed5555;
$locationColorTransp: #ed555532;
$filterColor: #0a9cf0;
$filterColorTransp: #0a9cf032;
$fakewhitetranspColor: #ffffff50;
$grayBackgroundColor: #e9e9e9;
$sideBarWidth: 34px;
$defaultMargin: 17px;
$defaultBorderRadius: 6px;
$NavBarHeight: 98px;
@media screen and (max-width: 1024px) { // TABLET
}
@media screen and (max-width: 321px) { // PHONE
    $NavBarHeight: 74px !important;
}