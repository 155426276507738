@import '@angular/material/theming';
@import "./theme/variables.scss";
@include mat-core();
$custom-primary: (
    50 : #e9f4ff,
    100 : #c8e3ff,
    200 : #a3d1ff,
    300 : #7ebfff,
    400 : #62b1ff,
    500 : #46a3ff,
    600 : #3f9bff,
    700 : #3791ff,
    800 : #2f88ff,
    900 : #2077ff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #cfe1ff,
    A700 : #b6d0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$custom-accent: (
    50 : #fffee6,
    100 : #fffcc0,
    200 : #fffa97,
    300 : #fff76d,
    400 : #fff64d,
    500 : #fff42e,
    600 : #fff329,
    700 : #fff123,
    800 : #ffef1d,
    900 : #ffec12,
    A100 : #ffffff,
    A200 : #fffef9,
    A400 : #fffac6,
    A700 : #fff8ad,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$custom-danger: (
    50 : #fce0e5,
    100 : #f7b3be,
    200 : #f18093,
    300 : #eb4d68,
    400 : #e72647,
    500 : #e30027,
    600 : #e00023,
    700 : #dc001d,
    800 : #d80017,
    900 : #d0000e,
    A100 : #fff8f8,
    A200 : #ffc5c7,
    A400 : #ff9295,
    A700 : #ff797c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$my-app-primary: mat-palette($custom-primary);
$my-app-accent:  mat-palette($custom-accent);
$my-app-warn:    mat-palette($custom-danger);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
.alternate-theme {
  $alternate-primary: mat-palette($my-app-primary);
  $alternate-accent:  mat-palette($mat-yellow, 400);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
}
.mat-spinner.op1 {
    opacity: 1.0 !important;
}
.mat-spinner > svg > circle {
    stroke: $primary;
}
